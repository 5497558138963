'use client'

import type { FC } from 'react'
import { Button, ChevronLeftIcon } from 'ui'
import { cn } from 'utils-tailwindcss'
import type { ButtonProps } from 'ui'
import { FormattedMessage } from '@/components/formatted-message'
import { useRouter } from '@/navigation'

type GoBackButtonProps = ButtonProps

export const GoBackButton: FC<GoBackButtonProps> = ({
  onClick,
  className,
  variant = 'link',
  ...restProps
}) => {
  const router = useRouter()

  return (
    <Button
      {...restProps}
      className={cn('w-fit', className)}
      onClick={(e) => {
        onClick?.(e)
        router.back()
      }}
      variant={variant}
    >
      <ChevronLeftIcon className="inline-block mr-2" size={20} />
      <FormattedMessage id="global.back" />
    </Button>
  )
}
